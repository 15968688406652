import authModule from '@/store/modules/auth';
import {
    CreateContactCategoryInfo, SearchInfo, UpdateContactCategoryInfo,
} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const ContactsCategoriesList = {
    url: `${BaseApiUrl}/contact-categories`,
    method: 'get',
    options: authoptions,
}
export const ContactCategoryContactsList = (categoryId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/contact-categories/${categoryId}?page=${page}` : `${BaseApiUrl}/contact-categories/${categoryId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    }
}
export const CreateContactsCategory = (ctegoryInfo: CreateContactCategoryInfo) => {
    return {
        url: `${BaseApiUrl}/contact-categories/create`,
        method: 'post',
        options: authoptions,
        payload: { ...ctegoryInfo }
    };
}

export const UpdateContactsCategory = (ctegoryId: number, ctegoryInfo: UpdateContactCategoryInfo) => {
    return {
        url: `${BaseApiUrl}/contact-categories/${ctegoryId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...ctegoryInfo }
    };
}

export const DeleteContactsCategory = (ctegoryId: number) => {
    return {
        url: `${BaseApiUrl}/contact-categories/${ctegoryId}/delete`,
        method: 'delete',
        options: authoptions,

    };
}

export const SearchCategoryContacts = (searchInfo: SearchInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/contact-categories/${categoryId}/search?page=${page}` : `${BaseApiUrl}/contact-categories/${categoryId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}