import { Module } from 'vuex';
import service from '@/store/services/tabs-company-service';
import { TabItemsCollection} from "@/types";

interface TabCompaniesState {
    tabCompaniesList: TabItemsCollection;
}

const state: TabCompaniesState  = {
    tabCompaniesList: { items: [] }
};
const mutations = {
    SET_TAB_COMPANIES_ITEMS: (state: TabCompaniesState, tabCompaniesList: TabItemsCollection) => {
        state.tabCompaniesList = tabCompaniesList;
    },
};
const actions = {
    getTabCompaniesList({ commit, dispatch }: any, params: any) {
        return service.getTabCompanies(params)
            .then(({ items }: any) => {
                commit('SET_TAB_COMPANIES_ITEMS', items);
            });
    },
    createTabCompany({ commit, dispatch }: any, params: any) {
        return service.createTabCompany(params)
            .then(({ items }: any) => {
                commit('SET_TAB_COMPANIES_ITEMS', items);
            });
    },
    updateTabCompany({ commit, dispatch }: any, params: any) {
        return service.updateTabCompany(params)
            .then(({ items }: any) => {
                commit('SET_TAB_COMPANIES_ITEMS', items);
            });
    },
    deleteTabCompany({ commit, dispatch }: any, params: any) {
        return service.deleteTabCompany(params)
            .then(({ items }: any) => {
                commit('SET_TAB_COMPANIES_ITEMS', items);
            });
    },
    searchTabCompanies({ commit, dispatch }: any, params: any) {
        return service.searchTabCompanies(params)
            .then(({ items }: any) => {
               commit('SET_TAB_COMPANIES_ITEMS', items);
            });
    }
};
const getters = {
    tabCompaniesList: (state: TabCompaniesState) => state.tabCompaniesList,
};
const tabCompanies: Module<TabCompaniesState, any> = {
    state,
    getters,
    actions,
    mutations,
};
export default tabCompanies;