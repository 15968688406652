import axios, { AxiosResponse } from "axios";
import {TabItemsCollection} from "@/types";
import {
    CreateTabDocument,
    DeleteTabDocument,
    GetTabDocumentsList, SearchTabsDocuments,
    UpdateTabDocument
} from "@/store/apiroutes/GeneralTabsRoutes";
import {CreateTabDocsInfo, SearchInfo, UpdateTabDocsInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getTabDocuments(params:{tabId: number, page?: number, link?: string}) {
    const tabItemsObj = GetTabDocumentsList(params.tabId, params.page ?? 1, params.link)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createTabDoc(payload:{tabId: number, tabDocsInfo: CreateTabDocsInfo}) {
    const tabItemsObj = CreateTabDocument(payload.tabId, payload.tabDocsInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabDoc(payload:{tabId: number, tabDocId: number, tabDocsInfo: UpdateTabDocsInfo}) {
    const tabItemsObj = UpdateTabDocument(payload.tabId, payload.tabDocId, payload.tabDocsInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteTabDoc(payload:{tabId: number, tabDocId: number}) {
    const tabItemsObj = DeleteTabDocument(payload.tabId, payload.tabDocId)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function serchTabDocs(payload: {searchInfo: SearchInfo, tabId: number, page?: number}) {
    const searchTabDocs = SearchTabsDocuments(payload.searchInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            searchTabDocs.method
            ](
            searchTabDocs.url,
            searchTabDocs.payload,
            searchTabDocs.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getTabDocuments,
    createTabDoc,
    updateTabDoc,
    deleteTabDoc,
    serchTabDocs
};