import authModule from '@/store/modules/auth';
import {CreateDocumentInfo, UpdateDocumentInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}

export const CreateDocument = (documentInfo: CreateDocumentInfo ) => {
    return {
        url: `${BaseApiUrl}/document/create`,
        method: 'post',
        options: authoptions,
        payload: { ...documentInfo }
    };
}

export const UpdateDocument = (documentId: number, documentInfo: UpdateDocumentInfo) => {
    return {
        url: `${BaseApiUrl}/document/${documentId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...documentInfo }
    };
}

export const DeleteDocument = (documentId: number) => {
    return {
        url: `${BaseApiUrl}/document/${documentId}/delete`,
        method: 'delete',
        options: authoptions,
    };
}
