import { Module } from 'vuex';
import service from '@/store/services/tabs-videos-service';
import { TabItemsCollection} from "@/types";

interface TabVideoState {
    tabVideosList: TabItemsCollection;
}

const state: TabVideoState  = {
    tabVideosList: { items: [] }
};
const mutations = {
    SET_TAB_VIDEOS_ITEMS: (state: TabVideoState, tabVideosList: TabItemsCollection) => {
        state.tabVideosList = tabVideosList;
    },
};
const actions = {
    getTabVideosList({ commit, dispatch }: any, params: any) {
        return service.getTabVideos(params)
            .then(({ items }: any) => {
                commit('SET_TAB_VIDEOS_ITEMS', items);
            });
    },
    createTabVideo({ commit, dispatch }: any, params: any) {
        return service.createTabVideo(params)
            .then(({ items }: any) => {
                commit('SET_TAB_VIDEOS_ITEMS', items);
            });
    },
    updateTabVideo({ commit, dispatch }: any, params: any) {
        return service.updateTabVideo(params)
            .then(({ items }: any) => {
                commit('SET_TAB_VIDEOS_ITEMS', items);
            });
    },
    deleteTabVideo({ commit, dispatch }: any, params: any) {
        return service.deleteTabVideo(params)
            .then(({ items }: any) => {
                commit('SET_TAB_VIDEOS_ITEMS', items);
            });
    },
    searchTabVideos({ commit, dispatch }: any, params: any) {
        return service.searchTabVideos(params)
            .then(({ items }: any) => {
                commit('SET_TAB_VIDEOS_ITEMS', items);
            });
    }
};
const getters = {
    tabVideosList: (state: TabVideoState) => state.tabVideosList,
};
const tabVideos: Module<TabVideoState, any> = {
    state,
    getters,
    actions,
    mutations,
};
export default tabVideos;