import authModule from '@/store/modules/auth';
import {CategoryInfo, SearchInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const CategoriesList = {
    url: `${BaseApiUrl}/categories`,
    method: 'get',
    options: authoptions,
}
export const CategoriesMenuList = {
    url: `${BaseApiUrl}/menu-categories`,
    method: 'get',
    options: authoptions,
}
export const showCategoryDocuments = (categoryId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/category/${categoryId}?page=${page}` : `${BaseApiUrl}/category/${categoryId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    }
}

export const CreateCategory = (categoryInfo: CategoryInfo) => {
    return {
        url: `${BaseApiUrl}/category/create`,
        method: 'post',
        options: authoptions,
        payload: { ...categoryInfo }
    };
}

export const UpdateCategory = (categoryId: number, categoryInfo: CategoryInfo) => {
    return {
        url: `${BaseApiUrl}/category/${categoryId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...categoryInfo }
    };
}

export const DeleteCategory = (categoryId: number) => {
    return {
        url: `${BaseApiUrl}/category/${categoryId}/delete`,
        method: 'delete',
        options: authoptions,
    };
}

export const SearchCategoryDocuments = (searchInfo: SearchInfo, categoryId: number, page: any) => {
    return {
        url: page ? `${BaseApiUrl}/category/${categoryId}/search?page=${page}` : `${BaseApiUrl}/category/${categoryId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}