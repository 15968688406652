import { Module } from 'vuex';

import service from '@/store/services/contacts-categories-service';
import {ContactCategory, ContactCategoryCollection, ContactCollection} from "@/types";


interface ContactsCategoriesState {
    contactCategoriesList: ContactCategoryCollection[];
    categoryContactList: ContactCategory;

}

const state: ContactsCategoriesState = {
    contactCategoriesList: [],
    categoryContactList: {
        id: 0,
        title: '',
        contacts: 0
    },
};

const mutations = {
    SET_CONTACT_CATEGORIES_LIST: (state: ContactsCategoriesState, contactCategoriesList: ContactCategoryCollection[]) => {
        state.contactCategoriesList = contactCategoriesList;
    },
    SET_CATEGORY_CONTACTS: (state: ContactsCategoriesState, category: ContactCategory) => {
        state.categoryContactList = category;
    }
};

const actions = {
    contactsCategoriesList({ commit, dispatch }: any, params: any) {
        return service.getContactsCategories()
            .then(({ categories }: any) => {
                commit('SET_CONTACT_CATEGORIES_LIST', categories);
            });
    },
    createContactsCategory({ commit, dispatch }: any, params: any) {
        return service.createContactCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CONTACT_CATEGORIES_LIST', categories);
            });
    },
    updateContactsCategory({ commit, dispatch }: any, params: any) {
        return service.updateContactCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CONTACT_CATEGORIES_LIST', categories);
            });
    },
    deleteContactsCategory({ commit, dispatch }: any, params: any) {
        return service.deleteContactCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CONTACT_CATEGORIES_LIST', categories);
            });
    },
    showCategoryContacts({ commit, dispatch }: any, params: any) {
        return service.showCategoryContacts(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_CONTACTS', category);
            });
    },
    searchCatContacts({ commit, dispatch }: any, params: any) {
        return service.serchCategoryContacts(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_CONTACTS', category);
            });
    }
};

const getters = {
    contactCategoriesList: (state: ContactsCategoriesState) => state.contactCategoriesList,
    categoryContactList: (state: ContactsCategoriesState) => state.categoryContactList,
};

const contactCategories: Module<ContactsCategoriesState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default contactCategories;
