
import axios, { AxiosResponse } from "axios";
import {CreateContactInfo, UpdateContactInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {CreateContact, DeleteContact, UpdateContact} from "@/store/apiroutes/ContactsRoutes";
import {ContactCategory} from "@/types";

async function createContact(contactInfo: CreateContactInfo) {
    const createContactObj = CreateContact(contactInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategory> = await axios[
            createContactObj.method
            ](
            createContactObj.url,
            createContactObj.payload,
            createContactObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateContact(payload: { contactId: number; contactInfo: UpdateContactInfo; }) {
    const updateCatObj = UpdateContact( payload.contactInfo, payload.contactId);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategory> = await axios[
            updateCatObj.method
            ](
            updateCatObj.url,
            updateCatObj.payload,
            updateCatObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteContact(contactId: number) {
    const deleteCatObj = DeleteContact(contactId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            deleteCatObj.method
            ](
            deleteCatObj.url,
            deleteCatObj.options
        );

        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    createContact,
    updateContact,
    deleteContact,
};