import { Module } from 'vuex';

import service from '@/store/services/users-service';
import { UsersCollection } from '@/types';

interface UsersState {
    usersList: UsersCollection[];
}

const state: UsersState = {
    usersList: [],
};

const mutations = {
    SET_USERS_LIST: (state: UsersState, usersList: UsersCollection[]) => {
        state.usersList = usersList;
    },
};

const actions = {
    userslist({ commit, dispatch }: any, params: any) {
        return service.getUsers(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    newUser({ commit, dispatch }: any, params: any) {
        return service.createUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    updateUser({ commit, dispatch }: any, params: any) {
        return service.updateUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    deactivateUser({ commit, dispatch }: any, params: any) {
        return service.deactivateUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    sortUsersByField({ commit, dispatch }: any, params: any) {
        return service.sortUsersByField(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    searchUsers({ commit, dispatch }: any, params: any) {
        return service.searchUsers(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
};

const getters = {
    usersList: (state: UsersState) => state.usersList,
};

const users: Module<UsersState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default users;
