import { Module } from 'vuex';
import service from '@/store/services/tabs-document-service';
import { TabItemsCollection} from "@/types";

interface TabDocsState {
    tabDocsList: TabItemsCollection;
}

const state: TabDocsState  = {
    tabDocsList: { items: [] }
};
const mutations = {
    SET_TAB_DOCS_ITEMS: (state: TabDocsState, tabDocsList: TabItemsCollection) => {
        state.tabDocsList = tabDocsList;
    },
};
const actions = {
    getTabDocsList({ commit, dispatch }: any, params: any) {
        return service.getTabDocuments(params)
            .then(({ items }: any) => {
                commit('SET_TAB_DOCS_ITEMS', items);
            });
    },
    createTabDoc({ commit, dispatch }: any, params: any) {
        return service.createTabDoc(params)
            .then(({ items }: any) => {
                commit('SET_TAB_DOCS_ITEMS', items);
            });
    },
    updateTabDoc({ commit, dispatch }: any, params: any) {
        return service.updateTabDoc(params)
            .then(({ items }: any) => {
                commit('SET_TAB_DOCS_ITEMS', items);
            });
    },
    deleteTabDoc({ commit, dispatch }: any, params: any) {
        return service.deleteTabDoc(params)
            .then(({ items }: any) => {
                commit('SET_TAB_DOCS_ITEMS', items);
            });
    },
    searchTabsDocs({ commit, dispatch }: any, params: any) {
        return service.serchTabDocs(params)
            .then(({ items }: any) => {
                commit('SET_TAB_DOCS_ITEMS', items);
            });
    }
};
const getters = {
    tabDocsList: (state: TabDocsState) => state.tabDocsList,
};
const tabDocs: Module<TabDocsState, any> = {
    state,
    getters,
    actions,
    mutations,
};
export default tabDocs;