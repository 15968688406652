import authModule from '@/store/modules/auth';
import {CreateEventCategoryInfo, UpdateEventCategoryInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const EventsCategoryList = {
    url: `${BaseApiUrl}/events-categories`,
    method: 'get',
    options: authoptions,
}
export const EventsCategoriesColorsList = {
    url: `${BaseApiUrl}/events-categories/colors`,
    method: 'get',
    options: authoptions,
}

export const CreateEventCategory = (ctegoryInfo: CreateEventCategoryInfo) => {
    return {
        url: `${BaseApiUrl}/events-categories/create`,
        method: 'post',
        options: authoptions,
        payload: { ...ctegoryInfo }
    };
}

export const UpdateEventCategory = (ctegoryInfo: UpdateEventCategoryInfo, ctegoryId: number) => {
    return {
        url: `${BaseApiUrl}/events-categories/${ctegoryId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...ctegoryInfo }
    };
}

export const DeleteEventCategory = (ctegoryId: number) => {
    return {
        url: `${BaseApiUrl}/events-categories/${ctegoryId}/delete`,
        method: 'delete',
        options: authoptions,

    };
}