import { Module } from 'vuex';

import service from '@/store/services/eventsCategories-service';
import {EventsCategoriesCollection, EventsCategoriesColorsCollection} from '@/types';

interface EventCategoriesState {
    eventCategoriesList: EventsCategoriesCollection[];
    eventCategoriesColorsList: EventsCategoriesColorsCollection[];
}

const state: EventCategoriesState = {
    eventCategoriesList: [],
    eventCategoriesColorsList: []
};

const mutations = {
    SET_EVENTS_CAT_LIST: (state: EventCategoriesState, eventCategoriesList: EventsCategoriesCollection[]) => {
        state.eventCategoriesList = eventCategoriesList;
    },
    SET_EVENTS_CAT_COLORS_LIST: (state: EventCategoriesState, eventCategoriesColorsList: EventsCategoriesColorsCollection[]) => {
        state.eventCategoriesColorsList = eventCategoriesColorsList;
    },
};

const actions = {
    eventCategoriesList({ commit, dispatch }: any, params: any) {
        return service.getEventsCategories()
            .then(({ categories }: any) => {
                commit('SET_EVENTS_CAT_LIST', categories);
            });
    },
    createEventCategory({ commit, dispatch }: any, params: any) {
        return service.createEventCategory(params)
            .then(({ categories }: any) => {
                commit('SET_EVENTS_CAT_LIST', categories);
            });
    },
    updateEventCategory({ commit, dispatch }: any, params: any) {
        return service.updateEventCategory(params)
            .then(({ categories }: any) => {
                commit('SET_EVENTS_CAT_LIST', categories);
            });
    },
    deleteEventCategory({ commit, dispatch }: any, params: any) {
        return service.deleteEventCategory(params)
            .then(({ categories }: any) => {
                commit('SET_EVENTS_CAT_LIST', categories);
            });
    },
    eventCategoriesColorsList({ commit, dispatch }: any, params: any) {
        return service.getEventsCategoriesColors()
            .then(({ colors }: any) => {
                commit('SET_EVENTS_CAT_COLORS_LIST', colors);
            });
    },
};

const getters = {
    eventCategoriesList: (state: EventCategoriesState) => state.eventCategoriesList,
    eventCategoriesColorsList: (state: EventCategoriesState) => state.eventCategoriesColorsList,
};

const eventCategories: Module<EventCategoriesState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default eventCategories;
