import { Module } from 'vuex';

import service from '@/store/services/global-service';
import {GlobalSearchCollection} from '@/types';

interface GlobalState {
    globalSearch: GlobalSearchCollection[];
}

const state: GlobalState = {
    globalSearch: [],
};

const mutations = {
    SET_GLOBAL_SEARCH_RESULT: (state: GlobalState, globalSearch: GlobalSearchCollection[]) => {
        state.globalSearch = globalSearch;
    }
};

const actions = {
    getGlobalSearchResult({ commit, dispatch }: any, params: any) {
        return service.getGlobalSearchResult(params)
            .then(({ result }: any) => {
                commit('SET_GLOBAL_SEARCH_RESULT', result);
            });
    },
};

const getters = {
    globalSearch: (state: GlobalState) => state.globalSearch,

};

const globalModule: Module<GlobalState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default globalModule;
