import {UsersList, CreateNewUser} from "@/store/apiroutes/UsersRoutes";
import axios, { AxiosResponse } from "axios";
import {User} from '@/types';
import {AuthUserProfile} from "@/store/apiroutes/UsersRoutes";

async function getProfile() {
    try {
        // @ts-ignore
        const response: AxiosResponse<User> = await axios[
            AuthUserProfile.method
        ](
            AuthUserProfile.url,
            AuthUserProfile.options
        );
        return {profile: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getProfile,

};