import { RouteLocationNormalized, Router } from 'vue-router';
import useStore from '@/store';
import { RouteNamesEnum } from '@/router/router.types';

export default function authMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: (p?: {
    name: RouteNamesEnum
}) => void) {
    if (!useStore.getters.isAuthenticated) {
        next({ name: RouteNamesEnum.login });
    } else {
        next();
    }
}
