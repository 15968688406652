
export enum NotificationType {
    SUCCESS = "success",
    ERROR = "error",
}

interface NotificationsState {
    notifications: { type: NotificationType; message: string }[];
}

const state: NotificationsState = {
    notifications: [],
};

const mutations = {
    addNotification(state: NotificationsState, notification: { type: NotificationType; message: string }) {
        state.notifications.push(notification);
    },
    removeNotification(state: NotificationsState, notification: { type: NotificationType; message: string }) {
        state.notifications = state.notifications.filter((n) => n !== notification);
    },
    clearNotifications(state: NotificationsState) {
        state.notifications = [];
    },
};

const actions = {
    showNotification({ commit }: any, { type, message }: { type: NotificationType; message: string }) {
        const notification = { type, message };
        commit('addNotification', notification);
    },
};

export default {
    state,
    mutations,
    actions,
};
