import axios, { AxiosResponse } from "axios";
import {TabItemsCollection} from "@/types";
import {
    CreateTabArticle,
    DeleteTabArticle,
    GetTabArticlesList, SearchTabArticles, SearchTabCompanies,
    UpdateTabArticle
} from "@/store/apiroutes/GeneralTabsRoutes";
import {CreateTabArticleInfo, SearchInfo, UpdateTabArticleInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getTabArticles(params:{tabId: number, page?: number, link?: string}) {
    const tabItemsObj = GetTabArticlesList(params.tabId, params.page ?? 1, params.link)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createTabArticle(payload:{tabId: number, tabArticleInfo: CreateTabArticleInfo}) {
    const tabItemsObj = CreateTabArticle(payload.tabId, payload.tabArticleInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabArticle(payload:{tabId: number, tabArticleId: number, tabArticleInfo: UpdateTabArticleInfo}) {
    const tabItemsObj = UpdateTabArticle(payload.tabId, payload.tabArticleId, payload.tabArticleInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteTabArticle(payload:{tabId: number, tabArticleId: number}) {
    const tabItemsObj = DeleteTabArticle(payload.tabId, payload.tabArticleId)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchTabArticles(payload: {searchInfo: SearchInfo, tabId: number, page?: number}) {
    const searchTabArticlesObj = SearchTabArticles(payload.searchInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            searchTabArticlesObj.method
            ](
            searchTabArticlesObj.url,
            searchTabArticlesObj.payload,
            searchTabArticlesObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getTabArticles,
    createTabArticle,
    updateTabArticle,
    deleteTabArticle,
    searchTabArticles
};