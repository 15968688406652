import axios, { AxiosResponse } from "axios";
import {TabItemsCollection} from "@/types";
import {
    CreateTabCompany, DeleteTabCompany,
    GetTabCompaniesList, SearchTabCompanies, SearchTabsDocuments, UpdateTabCompany

} from "@/store/apiroutes/GeneralTabsRoutes";
import {CreateTabCompanyInfo, SearchInfo, UpdateTabCompanyInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getTabCompanies(params:{tabId: number, page?: number, link?: string}) {
    const tabItemsObj = GetTabCompaniesList(params.tabId, params.page ?? 1, params.link)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createTabCompany(payload:{tabId: number, tabCompanyInfo: CreateTabCompanyInfo}) {
    const tabItemsObj = CreateTabCompany(payload.tabId, payload.tabCompanyInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabCompany(payload:{tabId: number, tabCompanyId: number, tabCompanyInfo: UpdateTabCompanyInfo}) {
    const tabItemsObj = UpdateTabCompany(payload.tabId, payload.tabCompanyId, payload.tabCompanyInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteTabCompany(payload:{tabId: number, tabCompanyId: number}) {
    const tabItemsObj = DeleteTabCompany(payload.tabId, payload.tabCompanyId)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchTabCompanies(payload: {searchInfo: SearchInfo, tabId: number, page?: number}) {
    const searchTabCompaniesObj = SearchTabCompanies(payload.searchInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            searchTabCompaniesObj.method
            ](
            searchTabCompaniesObj.url,
            searchTabCompaniesObj.payload,
            searchTabCompaniesObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getTabCompanies,
    createTabCompany,
    updateTabCompany,
    deleteTabCompany,
    searchTabCompanies
};