import {
    ValidateFields,
    ValidateCtegoriesFields,
    ValidateDocumentsFields,
    ValidateEventsFields,
    ValidateEventCtegoriesFields,
    ValidateContactCtegoriesFields,
    ValidateContactFields,
    ValidateGeneralTabsFields,
    ValidateTabDocsFields,
    ValidateTabCompaniesFields,
    ValidateTabArticlesFields,
    ValidateTabVideoFields,
    ValidateDashboardTextSlideFields,
    ValidateDashboardGreettingsTypesFields, ValidateDashboardOffersFields
} from '@/store/apiroutes/ValidateFieldsRoute';
import axios, {AxiosResponse} from "axios";
import {ValidateFieldsInfo} from "@/store/apiPayloadsFiles/payloadsInfo";


async function validateField(payload: { userId?: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateFields(payload.fieldsInfo, payload.userId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateCategoriesField(payload: { categoryId?: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateCtegoriesFields(payload.fieldsInfo, payload.categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateEventCategoriesField(payload: { categoryId?: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateEventCtegoriesFields(payload.fieldsInfo, payload.categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateContactCategoriesField(payload: { categoryId?: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateContactCtegoriesFields(payload.fieldsInfo, payload.categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateContactsField(payload: { contactId?: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateContactFields(payload.fieldsInfo, payload.contactId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateGeneralTabsField(payload: { tabId: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateGeneralTabsFields(payload.fieldsInfo, payload.tabId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateTabDocsField(payload: { tabId: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateTabDocsFields(payload.fieldsInfo, payload.tabId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateTabVideosField(payload: { tabId: number; fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateTabVideoFields(payload.fieldsInfo, payload.tabId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateTabCompanyField(payload: { tabId: number; companyId?: number, fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateTabCompaniesFields(payload.fieldsInfo, payload.tabId, payload.companyId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateTabArticleField(payload: { tabId: number; articleId?: number, fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateTabArticlesFields(payload.fieldsInfo, payload.tabId, payload.articleId);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateDocumentsField(payload: { fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateDocumentsFields(payload.fieldsInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateEventsField(payload: { fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateEventsFields(payload.fieldsInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateDashboardTextField(payload: { fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateDashboardTextSlideFields(payload.fieldsInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateDashboardGreetingsTypeField(payload: { fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateDashboardGreettingsTypesFields(payload.fieldsInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

async function validateDashboardSpecOfferField(payload: { fieldsInfo: ValidateFieldsInfo; }) {
    const validateFieldObj = ValidateDashboardOffersFields(payload.fieldsInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse = await axios[
            validateFieldObj.method
        ](
            validateFieldObj.url,
            validateFieldObj.payload,
            validateFieldObj.options
        );
        return {validated: response.data};
    } catch (error) {
        throw error;
    }
}

export default {
    validateField,
    validateCategoriesField,
    validateDocumentsField,
    validateEventsField,
    validateEventCategoriesField,
    validateContactCategoriesField,
    validateContactsField,
    validateGeneralTabsField,
    validateTabDocsField,
    validateTabCompanyField,
    validateTabArticleField,
    validateTabVideosField,
    validateDashboardTextField,
    validateDashboardGreetingsTypeField,
    validateDashboardSpecOfferField
}