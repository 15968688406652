
import axios, { AxiosResponse } from "axios";
import {EventsCategoriesCollection, EventsCategoriesColorsCollection} from '@/types';
import {
    EventsCategoryList,
    EventsCategoriesColorsList,
    CreateEventCategory, UpdateEventCategory, DeleteEventCategory
} from "@/store/apiroutes/EventsCategoryRoutes";
import {CreateEventCategoryInfo, UpdateEventCategoryInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getEventsCategories() {
    try {
        // @ts-ignore
        const response: AxiosResponse<EventsCategoriesCollection> = await axios[
            EventsCategoryList.method
        ](
            EventsCategoryList.url,
            EventsCategoryList.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getEventsCategoriesColors() {
    try {
        // @ts-ignore
        const response: AxiosResponse<EventsCategoriesColorsCollection> = await axios[
            EventsCategoriesColorsList.method
        ](
            EventsCategoriesColorsList.url,
            EventsCategoriesColorsList.options
        );
        return {colors: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createEventCategory(eventCategoryInfo: CreateEventCategoryInfo) {
    const createCatObj = CreateEventCategory(eventCategoryInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<EventsCategoriesColorsCollection> = await axios[
            createCatObj.method
            ](
            createCatObj.url,
            createCatObj.payload,
            createCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateEventCategory(payload: { categoryId: number; categoryInfo: UpdateEventCategoryInfo; }) {
    const updateCatObj = UpdateEventCategory(payload.categoryInfo, payload.categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse<EventsCategoriesCollection> = await axios[
            updateCatObj.method
            ](
            updateCatObj.url,
            updateCatObj.payload,
            updateCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteEventCategory(categoryId: number) {
    const deleteCatObj = DeleteEventCategory(categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            deleteCatObj.method
            ](
            deleteCatObj.url,
            deleteCatObj.options
        );

        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getEventsCategories,
    getEventsCategoriesColors,
    createEventCategory,
    updateEventCategory,
    deleteEventCategory
};