import { Module } from 'vuex';

import service from '@/store/services/categories-service';
import { CategoriesCollection, Category } from '@/types';

interface CategoriesState {
    categoriesList: CategoriesCollection[];
    categoriesMenuList: CategoriesCollection[];
    categoryDocuments: Category;
}

const state: CategoriesState = {
    categoriesList: [],
    categoriesMenuList: [],
    categoryDocuments: {
        id: 0,
        name: '',
        documents: []
    },
};

const mutations = {
    SET_CATEGORIES_LIST: (state: CategoriesState, categoriesList: CategoriesCollection[]) => {
        state.categoriesList = categoriesList;
    },
    SET_CATEGORIES_MENU_LIST: (state: CategoriesState, categoriesMenuList: CategoriesCollection[]) => {
        state.categoriesMenuList = categoriesMenuList;
    },
    SET_CATEGORY_DOCUMENTS: (state: CategoriesState, category: Category) => {
        state.categoryDocuments = category;
    }
};

const actions = {
    categoriesList({ commit, dispatch }: any, params: any) {
        return service.getCategories()
            .then(({ categories }: any) => {
                commit('SET_CATEGORIES_LIST', categories);
            });
    },
    categoriesMenuList({ commit, dispatch }: any, params: any) {
        return service.getMenuCategories()
            .then(({ categoriesMenu }: any) => {
                commit('SET_CATEGORIES_MENU_LIST', categoriesMenu);
            });
    },
    createCategory({ commit, dispatch }: any, params: any) {
        return service.createCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CATEGORIES_LIST', categories);
            });
    },
    updateCategory({ commit, dispatch }: any, params: any) {
        return service.updateCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CATEGORIES_LIST', categories);
            });
    },
    deleteCategory({ commit, dispatch }: any, params: any) {
        return service.deleteCategory(params)
            .then(({ categories }: any) => {
                commit('SET_CATEGORIES_LIST', categories);
            });
    },
    showCategoryDocs({ commit, dispatch }: any, params: any) {
        return service.showCategoryDocs(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_DOCUMENTS', category);
            });
    },
    serchCategoryDocs({ commit, dispatch }: any, params: any) {
        return service.serchCategoryDocs(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_DOCUMENTS', category);
            });
    },
};

const getters = {
    categoriesList: (state: CategoriesState) => state.categoriesList,
    categoriesMenuList: (state: CategoriesState) => state.categoriesMenuList,
    categoryDocuments: (state: CategoriesState) => state.categoryDocuments,
};

const categories: Module<CategoriesState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default categories;
