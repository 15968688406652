import { Module } from 'vuex';

import service from '@/store/services/dashboardSpecOffer-service';
import {DashBoardSpetialOfferCollection} from '@/types';

interface DashboardSpecOffersSliderState {
    dashboardSpecOffersList: DashBoardSpetialOfferCollection[];
    
}

const state: DashboardSpecOffersSliderState = {
    dashboardSpecOffersList: [],
};

const mutations = {
    SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST: (state: DashboardSpecOffersSliderState, dashboardSpecOffersList: DashBoardSpetialOfferCollection[]) => {
        state.dashboardSpecOffersList = dashboardSpecOffersList;
    },
};

const actions = {
    dashboardSpecOffersList({ commit, dispatch }: any, params: any) {
        return service.getdashboardSpecOffersSlides()
            .then(({ offers }: any) => {
                commit('SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST', offers);
            });
    },
    dashboardAllSpecOffers({ commit, dispatch }: any, params: any) {
        return service.getAllDashboardSpecOffersSlides(params)
            .then(({ offers }: any) => {
                commit('SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST', offers);
            });
    },
    createSpecOffersSlide({ commit, dispatch }: any, params: any) {
        return service.createDasboardTextSlide(params)
            .then(({ offers }: any) => {
                commit('SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST', offers);
            });
    },
    updateSpecOffersSlide({ commit, dispatch }: any, params: any) {
        return service.updateDasboardTextSlide(params)
            .then(({ offers }: any) => {
                commit('SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST', offers);
            });
    },
    deleteSpecOffersSlide({ commit, dispatch }: any, params: any) {
        return service.deleteDasboardTextSlide(params)
            .then(({ offers }: any) => {
                commit('SET_DASHBOARD_SPEC__OFFERS_SLIDES_LIST', offers);
            });
    },
};

const getters = {
    dashboardSpecOffersList: (state: DashboardSpecOffersSliderState) => state.dashboardSpecOffersList,

};

const dashboard_spec_offers_slides: Module<DashboardSpecOffersSliderState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboard_spec_offers_slides;
