import {IconsList} from "@/store/apiroutes/IconsRoutes";
import axios, { AxiosResponse } from "axios";

async function getIcons() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            IconsList.method
        ](
            IconsList.url,
            IconsList.options
        );
        return {icons: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getIcons,
};