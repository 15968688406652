import { Module } from 'vuex';

import service from '@/store/services/icons-service';

interface IconsState {
    iconsList: [];
}

const state: IconsState = {
    iconsList: [],
};

const mutations = {
    SET_ICONS_LIST: (state: IconsState, iconsList: []) => {
        state.iconsList = iconsList;
    },
};

const actions = {
    iconsList({ commit, dispatch }: any, params: any) {
        return service.getIcons()
            .then(({ icons }: any) => {
                commit('SET_ICONS_LIST', icons);
            });
    },
};

const getters = {
    iconsList: (state: IconsState) => state.iconsList,
};

const icons: Module<IconsState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default icons;
