import authModule from '@/store/modules/auth';
import {CreateEventInfo, UpdateEventInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const EventsList = (month?: number) => {
    return {
        url: month ? `${BaseApiUrl}/events/${month}` : `${BaseApiUrl}/events`,
        method: 'get',
        options: authoptions,
    }

}
export const CreateEvent = (eventInfo: CreateEventInfo ) => {
    return {
        url: `${BaseApiUrl}/events/create`,
        method: 'post',
        options: authoptions,
        payload: { ...eventInfo }
    }

}
export const UpdateEvent = (eventInfo: UpdateEventInfo, eventId: number ) => {
    return {
        url: `${BaseApiUrl}/events/${eventId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...eventInfo }
    }

}

export const DeleteEvent = (eventId: number) => {
    return {
        url: `${BaseApiUrl}/events/${eventId}/delete`,
        method: 'delete',
        options: authoptions,
    }

}