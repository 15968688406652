import { Module } from 'vuex';

import service from '@/store/services/events-service';
import { EventsCollection } from '@/types';

interface EventsState {
    eventsList: EventsCollection[];
}

const state: EventsState = {
    eventsList: [],
};

const mutations = {
    SET_EVENTS_LIST: (state: EventsState, eventsList: EventsCollection[]) => {
        state.eventsList = eventsList;
    },
};

const actions = {
    eventsList({ commit, dispatch }: any, params: any) {
        return service.getEvents(params)
            .then(({ events }: any) => {
                commit('SET_EVENTS_LIST', events);
            });
    },
    createEvent({ commit, dispatch }: any, params: any) {
        return service.createEvent(params)
            .then(({ events }: any) => {
                commit('SET_EVENTS_LIST', events);
            });
    },
    updateEvent({ commit, dispatch }: any, params: any) {
        return service.updateEvent(params)
            .then(({ events }: any) => {
                commit('SET_EVENTS_LIST', events);
            });
    },
    deleteEvent({ commit, dispatch }: any, params: any) {
        return service.deleteEvent(params)
            .then(({ events }: any) => {
                commit('SET_EVENTS_LIST', events);
            });
    },
};

const getters = {
    eventsList: (state: EventsState) => state.eventsList,
};

const events: Module<EventsState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default events;
