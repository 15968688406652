import {CreateDocument, UpdateDocument, DeleteDocument} from "@/store/apiroutes/DocumentsRoutes";
import axios, { AxiosResponse } from "axios";
import {Category} from '@/types';
import {CreateDocumentInfo, UpdateDocumentInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function createDocument(documentInfo: CreateDocumentInfo) {
    const createDocObj = CreateDocument(documentInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            createDocObj.method
            ](
            createDocObj.url,
            createDocObj.payload,
            createDocObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateDocument(payload: { documentId: number; documentInfo: UpdateDocumentInfo; }) {
    const updateDocObj = UpdateDocument(payload.documentId, payload.documentInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            updateDocObj.method
            ](
            updateDocObj.url,
            updateDocObj.payload,
            updateDocObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteDocument(documentId: number) {
    const deleteDocObj = DeleteDocument(documentId);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            deleteDocObj.method
            ](
            deleteDocObj.url,
            deleteDocObj.options
        );

        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    createDocument,
    updateDocument,
    deleteDocument,
};