import authModule from '@/store/modules/auth';
import {CreateContactInfo, UpdateContactInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const CreateContact = (contactInfo: CreateContactInfo) => {
    return {
        url: `${BaseApiUrl}/contacts/create`,
        method: 'post',
        options: authoptions,
        payload: { ...contactInfo }
    };
}

export const UpdateContact = (contactInfo: UpdateContactInfo, contactId: number) => {
    return {
        url: `${BaseApiUrl}/contacts/${contactId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...contactInfo }
    };
}

export const DeleteContact = (contactId: number) => {
    return {
        url: `${BaseApiUrl}/contacts/${contactId}/delete`,
        method: 'delete',
        options: authoptions,

    };
}