import { Module } from 'vuex';

import service from '@/store/services/profile-service';
import { User } from '@/types';

interface ProfileState {
    authUserProfile: User;
}


const state: ProfileState = {
    authUserProfile: {
        id: 0,
        username: '',
        email: '',
        ID_number: '',
        phone: '',
        role: '',
        first_name: '',
        last_name: '',
        birthdate: ''
    },
};

const mutations = {
    SET_AUTH_USER_PROFILE: (state: ProfileState, authUserProfile: User) => {
        state.authUserProfile = authUserProfile;
    },
};

const actions = {
    authUserProfile({ commit, dispatch }: any, params: any) {
        return service.getProfile()
            .then(({ profile }: any) => {
                commit('SET_AUTH_USER_PROFILE', profile);
            });
    },
};

const getters = {
    authUserProfile: (state: ProfileState) => state.authUserProfile,
};

const profile: Module<ProfileState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default profile;