import authModule from '@/store/modules/auth';
import {
    UpdateDasboardGreetingTypeSlideInfo,
} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL + '/dashboard/greetings_slider';
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const DasboardGreetingsSlidesList = {
    url: `${BaseApiUrl}`,
    method: 'get',
    options: authoptions,
}
export const DasboardGreetingsTypesList = {
    url: `${BaseApiUrl}/types`,
    method: 'get',
    options: authoptions,
}

export const UpdateDasboardGreetingsTypeSlide = (itemId: number, dashboardGreetingsTypeSlideInfo: UpdateDasboardGreetingTypeSlideInfo) => {
    return {
        url: `${BaseApiUrl}/${itemId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardGreetingsTypeSlideInfo }
    };
}
