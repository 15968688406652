
import axios, { AxiosResponse } from "axios";
import {DashBoardGreettingTypeCollection} from '@/types';
import {UpdateDasboardGreetingTypeSlideInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {
    DasboardGreetingsSlidesList,
    DasboardGreetingsTypesList,
    UpdateDasboardGreetingsTypeSlide
} from "@/store/apiroutes/DasboardGreetingsSlidesRoutes";

async function getdashboardGreetingSlides() {
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardGreettingTypeCollection> = await axios[
            DasboardGreetingsSlidesList.method
        ](
            DasboardGreetingsSlidesList.url,
            DasboardGreetingsSlidesList.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function getAllDashboardGreetingsTypes() {
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardGreettingTypeCollection> = await axios[
            DasboardGreetingsTypesList.method
        ](
            DasboardGreetingsTypesList.url,
            DasboardGreetingsTypesList.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function updateDasboardDashboardGreetingsSlide(payload: {itemId: number; dashboardGreetingsTypeSlideInfo: UpdateDasboardGreetingTypeSlideInfo }) {
    const updateGreetingSlideObj = UpdateDasboardGreetingsTypeSlide(payload.itemId, payload.dashboardGreetingsTypeSlideInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardGreettingTypeCollection> = await axios[
            updateGreetingSlideObj.method
            ](
            updateGreetingSlideObj.url,
            updateGreetingSlideObj.payload,
            updateGreetingSlideObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getdashboardGreetingSlides,
    getAllDashboardGreetingsTypes,
    updateDasboardDashboardGreetingsSlide,
};