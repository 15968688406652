import { Module } from 'vuex';

import service from '@/store/services/documents-service';
import { Category } from '@/types';

interface DocumentsState {
    documentsOfcategory: Category;
}

const state: DocumentsState = {
    documentsOfcategory: {
        id: 0,
        name: '',
        documents: []
    },
};

const mutations = {
    SET_DOCUMENTS_OF_CATEGORY: (state: DocumentsState, category: Category) => {
        state.documentsOfcategory = category;
    }
};

const actions = {
    createDocument({ commit, dispatch }: any, params: any) {
        return service.createDocument(params)
            .then(({ category }: any) => {
                commit('SET_DOCUMENTS_OF_CATEGORY', category);
            });
    },
    updateDocument({ commit, dispatch }: any, params: any) {
        return service.updateDocument(params)
            .then(({ category }: any) => {
                commit('SET_DOCUMENTS_OF_CATEGORY', category);
            });
    },
    deleteDocument({ commit, dispatch }: any, params: any) {
        return service.deleteDocument(params)
            .then(({ category }: any) => {
                commit('SET_DOCUMENTS_OF_CATEGORY', category);
            });
    },

};

const getters = {
    documentsOfcategory: (state: DocumentsState) => state.documentsOfcategory,
};

const documents: Module<DocumentsState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default documents;
