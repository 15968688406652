import { RouteLocationNormalized, Router } from 'vue-router';
import useStore from '@/store';
import { RouteNamesEnum } from '@/router/router.types';

export default async function checkUserRole(to: RouteLocationNormalized, from: RouteLocationNormalized, next: (p?: {
    name: RouteNamesEnum
}) => void) {
    const isAdminUser = await isAdmin();

    if(isAdminUser) {
        next();
    } else {
        next( {name: RouteNamesEnum.dashboard});
    }
}

export async function isAdmin() {
    await useStore.dispatch('authUserProfile');
    const authUser = useStore.getters.authUserProfile;
    return authUser && (authUser.role === 'admin' || authUser.role === 'super_admin');
}