import { Module } from 'vuex';

import service from '@/store/services/dashboardTextSlide-service';
import {DashboardTextSlidesCollection} from '@/types';

interface DashboardTextSlideState {
    dashboardTextSlidesList: DashboardTextSlidesCollection[];
    
}

const state: DashboardTextSlideState = {
    dashboardTextSlidesList: [],
};

const mutations = {
    SET_DASHBOARD_TEXT_SLIDES_LIST: (state: DashboardTextSlideState, dashboardTextSlidesList: DashboardTextSlidesCollection[]) => {
        state.dashboardTextSlidesList = dashboardTextSlidesList;
    },
};

const actions = {
    dashboardTextSlidesList({ commit, dispatch }: any, params: any) {
        return service.getdashboardTextSlides()
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    dashboardAllTextSlides({ commit, dispatch }: any, params: any) {
        return service.getAllDashboardTextSlides(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    createDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.createDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    updateDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.updateDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    deleteDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.deleteDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
};

const getters = {
    dashboardTextSlidesList: (state: DashboardTextSlideState) => state.dashboardTextSlidesList,

};

const dashboard_text_slides: Module<DashboardTextSlideState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboard_text_slides;
