
import {CreateEvent, DeleteEvent, EventsList, UpdateEvent} from "@/store/apiroutes/EventsRoutes";
import axios, { AxiosResponse } from "axios";
import {Category, RolesCollection} from '@/types';
import {CreateEventInfo, UpdateEventInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getEvents(params?: number) {
    const eventsRoute = EventsList(params);
    try {
        // @ts-ignore
        const response: AxiosResponse<RolesCollection> = await axios[
            eventsRoute.method
        ](
            eventsRoute.url,
            eventsRoute.options
        );
        return {events: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createEvent(eventInfo: CreateEventInfo) {
    const createEventObj = CreateEvent(eventInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            createEventObj.method
            ](
            createEventObj.url,
            createEventObj.payload,
            createEventObj.options
        );
        return {events: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateEvent(payload: {eventInfo: UpdateEventInfo, eventId: number}) {
    const updateEventObj = UpdateEvent(payload.eventInfo, payload.eventId);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            updateEventObj.method
            ](
            updateEventObj.url,
            updateEventObj.payload,
            updateEventObj.options
        );
        return {events: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function deleteEvent(params: number) {
    const deleteEventsRoute = DeleteEvent(params);
    try {
        // @ts-ignore
        const response: AxiosResponse<RolesCollection> = await axios[
            deleteEventsRoute.method
            ](
            deleteEventsRoute.url,
            deleteEventsRoute.options
        );
        return {events: response.data} ;
    } catch (error) {
        throw error;
    }
}


export default {
    getEvents,
    createEvent,
    updateEvent,
    deleteEvent
};