import axios, { AxiosResponse } from "axios";
import {TabItemsCollection} from "@/types";
import {
    CreateTabVideo, DeleteTabVideo,
    GetTabVideosList, SearchTabVideos, UpdateTabVideo

} from "@/store/apiroutes/GeneralTabsRoutes";
import {CreateTabVideoInfo, SearchInfo, UpdateTabVideoInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getTabVideos(params:{tabId: number, page?: number, link?: string}) {
    const tabItemsObj = GetTabVideosList(params.tabId, params.page ?? 1, params.link)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createTabVideo(payload:{tabId: number, tabVideoInfo: CreateTabVideoInfo}) {
    const tabItemsObj = CreateTabVideo(payload.tabId, payload.tabVideoInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabVideo(payload:{tabId: number, tabArticleId: number, tabVideoInfo: UpdateTabVideoInfo}) {
    const tabItemsObj = UpdateTabVideo(payload.tabId, payload.tabArticleId, payload.tabVideoInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteTabVideo(payload:{tabId: number, tabArticleId: number}) {
    const tabItemsObj = DeleteTabVideo(payload.tabId, payload.tabArticleId)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchTabVideos(payload: {searchInfo: SearchInfo, tabId: number, page?: number}) {
    const searchTabVideosObj = SearchTabVideos(payload.searchInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            searchTabVideosObj.method
            ](
            searchTabVideosObj.url,
            searchTabVideosObj.payload,
            searchTabVideosObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getTabVideos,
    createTabVideo,
    updateTabVideo,
    deleteTabVideo,
    searchTabVideos
};