import {Module} from 'vuex';
import axios from 'axios';
import { Commit } from 'vuex';

import {LOGIN, LOGOUT} from "@/store/apiroutes/UsersRoutes";

interface AuthState {
    isAuthenticated: boolean;
    token: string | null;
    loginError: string | null;
}

interface ErrorResponse {
    response: {
        data: any;
    };
}
const state: AuthState = {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    token: localStorage.getItem("vue-authenticate.vueauth_access_token"),
    loginError: null
};

const getters = {
    isAuthenticated: (state: AuthState) => state.isAuthenticated,
    loginError: (state: AuthState) => state.loginError,
    access_token: (state: AuthState) => state.token
};

const mutations = {
    setToken(state: AuthState, token: string) {
        localStorage.setItem('vue-authenticate.vueauth_access_token', token)
        state.token = token;
        state.isAuthenticated = !!token;
        window.location.reload();
    },
    clearToken(state: AuthState) {
        state.token = null;
        state.isAuthenticated = false;
        localStorage.removeItem('vue-authenticate.vueauth_access_token')
    },
    setLoginError(state: AuthState, error: string) {
        state.loginError = error;
    }
};


const actions = {
    async login({commit}: { commit: Commit }, payload: { username: string; password: string }) {
        try {
            // @ts-ignore
            await axios[LOGIN(payload).method](LOGIN(payload).url, LOGIN(payload).payload, LOGIN(payload).options).then(response => commit('setToken', response.data.access_token));
        } catch (error) {
            const errorResponse = error as ErrorResponse;
            commit('setLoginError',  errorResponse.response.data);
        }
    },
    async logout ({commit}: { commit: Commit }) {
        // @ts-ignore
        await axios[LOGOUT.method](LOGOUT.url, LOGOUT.options).then(() => {
            commit('clearToken');
        }).catch(() => {
            commit('clearToken');
        });

    }

};


const auth: Module<AuthState, any> = {
    state,
    getters,
    mutations,
    actions
};

export default auth;
