import {
    CreateDasboardTextSlide, DasboardAllTextSlidesList,
    DasboardTextSlidesList, DeleteDasboardTextSlide, UpdateDasboardTextSlide

} from "@/store/apiroutes/DasboardTextSlidesRoutes";
import axios, { AxiosResponse } from "axios";
import {DashboardTextSlidesCollection} from '@/types';
import {CreateDasboardTextSlideInfo, UpdateDasboardTextSlideInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getdashboardTextSlides() {
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            DasboardTextSlidesList.method
        ](
            DasboardTextSlidesList.url,
            DasboardTextSlidesList.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function getAllDashboardTextSlides(params?: number) {
    const allTextSlidesObj =DasboardAllTextSlidesList(params);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            allTextSlidesObj.method
        ](
            allTextSlidesObj.url,
            allTextSlidesObj.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function createDasboardTextSlide(dashboardTextSlideInfo: CreateDasboardTextSlideInfo) {
    const createTextObj = CreateDasboardTextSlide(dashboardTextSlideInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            createTextObj.method
            ](
            createTextObj.url,
            createTextObj.payload,
            createTextObj.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateDasboardTextSlide(payload: { dashboardTextSlideId: number; dashboardTextSlideInfo: UpdateDasboardTextSlideInfo; }) {
    const updateDasboardTextSlideObj = UpdateDasboardTextSlide(payload.dashboardTextSlideId, payload.dashboardTextSlideInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            updateDasboardTextSlideObj.method
            ](
            updateDasboardTextSlideObj.url,
            updateDasboardTextSlideObj.payload,
            updateDasboardTextSlideObj.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteDasboardTextSlide(dashboardTextSlideId: number) {
    const deleteDasboardTextSlideObj = DeleteDasboardTextSlide(dashboardTextSlideId);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            deleteDasboardTextSlideObj.method
            ](
            deleteDasboardTextSlideObj.url,
            deleteDasboardTextSlideObj.options
        );

        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getdashboardTextSlides,
    createDasboardTextSlide,
    updateDasboardTextSlide,
    deleteDasboardTextSlide,
    getAllDashboardTextSlides
};