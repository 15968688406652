
import axios, { AxiosResponse } from "axios";
import {DashBoardSpetialOfferCollection, DashboardTextSlidesCollection} from '@/types';
import {CreateDasboardSpecOfferSlideInfo, UpdateDasboardSpecOfferSlideInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {
    CreateDasboardSpecOfferSlide, DasboardAllSpecialOffersList,
    DasboardSpecialOffersList, DeleteDasboardSpecOfferSlide, UpdateDasboardSpecOfferSlide
} from "@/store/apiroutes/DasboardSpecialOffersSlidesRoutes";
import {DasboardAllTextSlidesList} from "@/store/apiroutes/DasboardTextSlidesRoutes";

async function getdashboardSpecOffersSlides() {
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            DasboardSpecialOffersList.method
        ](
            DasboardSpecialOffersList.url,
            DasboardSpecialOffersList.options
        );
        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function getAllDashboardSpecOffersSlides(params?: number) {
    const allOffersSlidesObj = DasboardAllSpecialOffersList(params);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            allOffersSlidesObj.method
        ](
            allOffersSlidesObj.url,
            allOffersSlidesObj.options
        );
        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function createDasboardTextSlide(dashboardSpecOfferSlideInfo: CreateDasboardSpecOfferSlideInfo) {
    const createObj = CreateDasboardSpecOfferSlide(dashboardSpecOfferSlideInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            createObj.method
            ](
            createObj.url,
            createObj.payload,
            createObj.options
        );
        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateDasboardTextSlide(payload: { dashboardSpecOfferId: number; dashboardSpecOfferSlideInfo: UpdateDasboardSpecOfferSlideInfo; }) {
    const updateObj = UpdateDasboardSpecOfferSlide(payload.dashboardSpecOfferId, payload.dashboardSpecOfferSlideInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            updateObj.method
            ](
            updateObj.url,
            updateObj.payload,
            updateObj.options
        );
        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteDasboardTextSlide(dashboardSpecOfferId: number) {
    const deleteDasboardTextSlideObj = DeleteDasboardSpecOfferSlide(dashboardSpecOfferId);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            deleteDasboardTextSlideObj.method
            ](
            deleteDasboardTextSlideObj.url,
            deleteDasboardTextSlideObj.options
        );

        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getdashboardSpecOffersSlides,
    getAllDashboardSpecOffersSlides,
    createDasboardTextSlide,
    updateDasboardTextSlide,
    deleteDasboardTextSlide
};