import axios, { AxiosResponse } from "axios";
import {GeneralTabsCollection, TabItemsCollection} from "@/types";
import {GetGeneralTabsList, ShowGeneralTabItems, UpdateGeneralTab} from "@/store/apiroutes/GeneralTabsRoutes";
import {UpdateGeneralTabInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getGeneralTabsList() {
    try {
        // @ts-ignore
        const response: AxiosResponse<GeneralTabsCollection> = await axios[
            GetGeneralTabsList.method
            ](
            GetGeneralTabsList.url,
            GetGeneralTabsList.options
        );
        return {tabs: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function showGeneralTabItems(params:{tabId: number, page?: number}) {
    const tabItemsObj = ShowGeneralTabItems(params.tabId, params.page ?? 1)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateGeneralTab(payload:{tabId: number, generalTabInfo: UpdateGeneralTabInfo}) {
    const tabItemsObj = UpdateGeneralTab(payload.tabId, payload.generalTabInfo)
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            tabItemsObj.method
            ](
            tabItemsObj.url,
            tabItemsObj.payload,
            tabItemsObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getGeneralTabsList,
    showGeneralTabItems,
    updateGeneralTab
};