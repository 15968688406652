import authModule from '@/store/modules/auth';
import {
    CreateDasboardSpecOfferSlideInfo,
    CreateDasboardTextSlideInfo, UpdateDasboardSpecOfferSlideInfo,
    UpdateDasboardTextSlideInfo
} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL + '/dashboard/special_offers_slider';
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const DasboardSpecialOffersList = {
    url: `${BaseApiUrl}`,
    method: 'get',
    options: authoptions,
}
export const DasboardAllSpecialOffersList = (page?: number) =>  {
    return {
        url: page ? `${BaseApiUrl}/all_offers?page=${page}` : `${BaseApiUrl}/all_offers`,
        method: 'get',
        options: authoptions,
    }

}

export const CreateDasboardSpecOfferSlide = (dashboardSpecOfferInfo: CreateDasboardSpecOfferSlideInfo) => {
    return {
        url: `${BaseApiUrl}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardSpecOfferInfo }
    };
}

export const UpdateDasboardSpecOfferSlide = (dashboardSpecOfferId: number, dashboardSpecOfferInfo: UpdateDasboardSpecOfferSlideInfo) => {
    return {
        url: `${BaseApiUrl}/${dashboardSpecOfferId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardSpecOfferInfo }
    };
}

export const DeleteDasboardSpecOfferSlide = (dashboardSpecOfferId: number) => {
    return {
        url: `${BaseApiUrl}/${dashboardSpecOfferId}/delete`,
        method: 'delete',
        options: authoptions,
    };
}