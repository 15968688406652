import { Module } from 'vuex';

import service from '@/store/services/contacts-service';
import {ContactCategory} from "@/types";


interface ContactsState {
    categoryContactsList: ContactCategory;

}

const state: ContactsState = {
    categoryContactsList: {
        id: 0,
        title: '',
        contacts: 0
    },
};

const mutations = {
    SET_CATEGORY_CONTACTS: (state: ContactsState, category: ContactCategory) => {
        state.categoryContactsList = category;
    }
};

const actions = {

    createContact({ commit, dispatch }: any, params: any) {
        return service.createContact(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_CONTACTS', category);
            });
    },
    updateContact({ commit, dispatch }: any, params: any) {
        return service.updateContact(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_CONTACTS', category);
            });
    },
    deleteContact({ commit, dispatch }: any, params: any) {
        return service.deleteContact(params)
            .then(({ category }: any) => {
                commit('SET_CATEGORY_CONTACTS', category);
            });
    }
};

const getters = {
    categoryContactsList: (state: ContactsState) => state.categoryContactsList,
};

const contacts: Module<ContactsState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default contacts;
