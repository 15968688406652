import { Module } from 'vuex';

import service from '@/store/services/dashboardGreetingsSlide-service';
import {DashBoardGreettingTypeCollection} from '@/types';

interface DashboardGreetingSlideState {
    dashboardGreetingSlidesList: DashBoardGreettingTypeCollection[];
    dashboardGreetingTypesList: DashBoardGreettingTypeCollection[];
    
}

const state: DashboardGreetingSlideState = {
    dashboardGreetingSlidesList: [],
    dashboardGreetingTypesList: [],
};

const mutations = {
    SET_DASHBOARD_GREETTINGS_SLIDES_LIST: (state: DashboardGreetingSlideState, dashboardGreetingSlidesList: DashBoardGreettingTypeCollection[]) => {
        state.dashboardGreetingSlidesList = dashboardGreetingSlidesList;
    },
    SET_DASHBOARD_GREETTINGS_TYPES_LIST: (state: DashboardGreetingSlideState, dashboardGreetingTypesList: DashBoardGreettingTypeCollection[]) => {
        state.dashboardGreetingTypesList = dashboardGreetingTypesList;
    },
};

const actions = {
    dashboardGreetingSlidesList({ commit, dispatch }: any, params: any) {
        return service.getdashboardGreetingSlides()
            .then(({ items }: any) => {
                commit('SET_DASHBOARD_GREETTINGS_SLIDES_LIST', items);
            });
    },
    dashboardAlGreetingTypes({ commit, dispatch }: any, params: any) {
        return service.getAllDashboardGreetingsTypes()
            .then(({ items }: any) => {
                commit('SET_DASHBOARD_GREETTINGS_TYPES_LIST', items);
            });
    },
    updateDashboardGreetingType({ commit, dispatch }: any, params: any) {
        return service.updateDasboardDashboardGreetingsSlide(params)
            .then(({ items }: any) => {
                commit('SET_DASHBOARD_GREETTINGS_TYPES_LIST', items);
            });
    },
};

const getters = {
    dashboardGreetingSlidesList: (state: DashboardGreetingSlideState) => state.dashboardGreetingSlidesList,
    dashboardGreetingTypesList: (state: DashboardGreetingSlideState) => state.dashboardGreetingTypesList,

};

const dashboard_greetings_slides: Module<DashboardGreetingSlideState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboard_greetings_slides;
