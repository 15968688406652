
import axios, { AxiosResponse } from "axios";
import {
    ContactCategoryContactsList,
    ContactsCategoriesList,
    CreateContactsCategory, DeleteContactsCategory, SearchCategoryContacts, UpdateContactsCategory
} from "@/store/apiroutes/ContactsCategoriesRoutes";
import {ContactCategory, ContactCategoryCollection} from "@/types";
import {CreateContactCategoryInfo, SearchInfo, UpdateContactCategoryInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getContactsCategories() {
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategoryCollection> = await axios[
            ContactsCategoriesList.method
        ](
            ContactsCategoriesList.url,
            ContactsCategoriesList.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function showCategoryContacts(payload: {categoryId: number, page?: number, link?: string}) {
    const showCatContacts = ContactCategoryContactsList(payload.categoryId, payload.page ?? 1, payload.link);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategory> = await axios[
            showCatContacts.method
        ](
            showCatContacts.url,
            showCatContacts.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function createContactCategory(categoryInfo: CreateContactCategoryInfo) {
    const createCatObj = CreateContactsCategory(categoryInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategoryCollection> = await axios[
            createCatObj.method
            ](
            createCatObj.url,
            createCatObj.payload,
            createCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateContactCategory(payload: { categoryId: number; categoryInfo: UpdateContactCategoryInfo; }) {
    const updateCatObj = UpdateContactsCategory(payload.categoryId, payload.categoryInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategoryCollection> = await axios[
            updateCatObj.method
            ](
            updateCatObj.url,
            updateCatObj.payload,
            updateCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteContactCategory(categoryId: number) {
    const deleteCatObj = DeleteContactsCategory(categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategoryCollection> = await axios[
            deleteCatObj.method
            ](
            deleteCatObj.url,
            deleteCatObj.options
        );

        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function serchCategoryContacts(payload: {searchInfo: SearchInfo, categoryId: number, page?: number}) {
    const searchCatContacts = SearchCategoryContacts(payload.searchInfo, payload.categoryId, payload.page ?? 1);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategory> = await axios[
            searchCatContacts.method
            ](
            searchCatContacts.url,
            searchCatContacts.payload,
            searchCatContacts.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getContactsCategories,
    showCategoryContacts,
    createContactCategory,
    updateContactCategory,
    deleteContactCategory,
    serchCategoryContacts
};