import {RolesList} from "@/store/apiroutes/RolesRoutes";
import axios, { AxiosResponse } from "axios";
import {RolesCollection} from '@/types';

async function getRoles() {
    try {
        // @ts-ignore
        const response: AxiosResponse<RolesCollection> = await axios[
            RolesList.method
        ](
            RolesList.url,
            RolesList.options
        );
        return {roles: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getRoles,
};