import { Module } from 'vuex';

import service from '@/store/services/roles-service';
import { RolesCollection } from '@/types';

interface RolesState {
    rolesList: RolesCollection[];
}

const state: RolesState = {
    rolesList: [],
};

const mutations = {
    SET_ROLES_LIST: (state: RolesState, rolesList: RolesCollection[]) => {
        state.rolesList = rolesList;
    },
};

const actions = {
    rolesList({ commit, dispatch }: any, params: any) {
        return service.getRoles()
            .then(({ roles }: any) => {
                commit('SET_ROLES_LIST', roles);
            });
    },
};

const getters = {
    rolesList: (state: RolesState) => state.rolesList,
};

const roles: Module<RolesState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default roles;
