import {LoginInfo, NewUserInfo, UpdateUserInfo, SearchInfo, SortUsersByFieldInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import authModule from '@/store/modules/auth';
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
};

// @ts-ignore
const token = await authModule.state.token;

const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}

export const LOGIN = ({username, password}: LoginInfo) => {
    return {
        url: `${BaseApiUrl}/user/login`,
        method: 'post',
        options: options,
        payload: {
            username: username,
            password: password
        }
    }
}
export const LOGOUT = {
    url: `${BaseApiUrl}/user/logout`,
    method: 'get',
    options: authoptions,
}

export const UsersList = (page: any) => {
    return {
        url: `${BaseApiUrl}/users?page=${page}`,
        method: 'get',
        options: authoptions,
    }
}

export const CreateNewUser = (userInfo: NewUserInfo) => {
    return {
        url: `${BaseApiUrl}/user/create`,
        method: 'post',
        options: authoptions,
        payload: { ...userInfo }
    }
}

export const AuthUserProfile = {
    url: `${BaseApiUrl}/user/profile`,
    method: 'get',
    options: authoptions,
}

export const UpdateUser = (userId: number, userInfo: UpdateUserInfo) => {
    return {
        url: `${BaseApiUrl}/user/update/${userId}`,
        method: 'put',
        options: authoptions,
        payload: { ...userInfo }
    }
}

export const DeactivateUser = (userId: number) => {
    return {
        url: `${BaseApiUrl}/user/deactivate/${userId}`,
        method: 'get',
        options: authoptions,
    }
}

export const SortUsersByField = (sortInfo: SortUsersByFieldInfo, page: any) => {
    return {
        url: page ? `${BaseApiUrl}/users/sorting?page=${page}` : `${BaseApiUrl}/users/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortInfo }
    }
}

export const SearchUsers = (searchInfo: SearchInfo) => {
    return {
        url: `${BaseApiUrl}/users/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}



