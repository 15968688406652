import {
    CategoriesList,
    CategoriesMenuList,
    CreateCategory,
    UpdateCategory,
    DeleteCategory,
    showCategoryDocuments,
    SearchCategoryDocuments
} from "@/store/apiroutes/CategoriesRoutes";
import axios, { AxiosResponse } from "axios";
import {CategoriesCollection} from '@/types';
import {CategoryInfo, SearchInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getCategories() {
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            CategoriesList.method
        ](
            CategoriesList.url,
            CategoriesList.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getMenuCategories() {
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            CategoriesMenuList.method
        ](
            CategoriesMenuList.url,
            CategoriesMenuList.options
        );
        return {categoriesMenu: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function showCategoryDocs(payload: {categoryId: number, page?: number, link?: string}) {
    const showCatDocs = showCategoryDocuments(payload.categoryId, payload.page ?? 1, payload.link);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            showCatDocs.method
        ](
            showCatDocs.url,
            showCatDocs.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function serchCategoryDocs(payload: {searchInfo: SearchInfo,categoryId: number, page: number}) {
    const searchCatDocs = SearchCategoryDocuments(payload.searchInfo, payload.categoryId, payload.page ?? 1);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            searchCatDocs.method
        ](
            searchCatDocs.url,
            searchCatDocs.payload,
            searchCatDocs.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createCategory(categoryInfo: CategoryInfo) {
    const createCatObj = CreateCategory(categoryInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            createCatObj.method
            ](
            createCatObj.url,
            createCatObj.payload,
            createCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateCategory(payload: { categoryId: number; categoryInfo: CategoryInfo; }) {
    const updateCatObj = UpdateCategory(payload.categoryId, payload.categoryInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            updateCatObj.method
            ](
            updateCatObj.url,
            updateCatObj.payload,
            updateCatObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteCategory(categoryId: number) {
    const deleteCatObj = DeleteCategory(categoryId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            deleteCatObj.method
            ](
            deleteCatObj.url,
            deleteCatObj.options
        );

        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getCategories,
    getMenuCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    showCategoryDocs,
    serchCategoryDocs
};