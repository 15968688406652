import {UsersList, CreateNewUser, UpdateUser, DeactivateUser, SortUsersByField, SearchUsers} from "@/store/apiroutes/UsersRoutes";
import axios, { AxiosResponse } from "axios";
import {UsersCollection} from '@/types'
import {NewUserInfo, UpdateUserInfo, SearchInfo, SortUsersByFieldInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getUsers(params: any) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            UsersList(params).method
        ](
            UsersList(params).url,
            UsersList(params).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createUser(userInfo: NewUserInfo) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            CreateNewUser(userInfo).method
        ](
            CreateNewUser(userInfo).url,
            CreateNewUser(userInfo).payload,
            CreateNewUser(userInfo).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateUser(payload: { userId: number; userInfo: UpdateUserInfo; }) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            UpdateUser(payload.userId, payload.userInfo).method
        ](
            UpdateUser(payload.userId, payload.userInfo).url,
            UpdateUser(payload.userId, payload.userInfo).payload,
            UpdateUser(payload.userId, payload.userInfo).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deactivateUser(userId: number) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            DeactivateUser(userId).method
        ](
            DeactivateUser(userId).url,
            DeactivateUser(userId).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortUsersByField(payload: {sortInfo: SortUsersByFieldInfo, page: number}) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            SortUsersByField(payload.sortInfo, payload.page ?? 1).method
        ](
            SortUsersByField(payload.sortInfo, payload.page ?? 1).url,
            SortUsersByField(payload.sortInfo, payload.page ?? 1).payload,
            SortUsersByField(payload.sortInfo, payload.page ?? 1).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchUsers(searchInfo: SearchInfo) {
    try {
        // @ts-ignore
        const response: AxiosResponse<UsersCollection> = await axios[
            SearchUsers(searchInfo).method
        ](
            SearchUsers(searchInfo).url,
            SearchUsers(searchInfo).payload,
            SearchUsers(searchInfo).options
        );
        return {users: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getUsers,
    createUser,
    updateUser,
    deactivateUser,
    sortUsersByField,
    searchUsers
};