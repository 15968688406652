import { Module } from 'vuex';
import service from '@/store/services/general-tabs-service';
import { GeneralTabsCollection, TabItemsCollection} from "@/types";

interface GeneralTabsState {
    generalTabsList: GeneralTabsCollection;
    generalTabItems: TabItemsCollection;
}

const state: GeneralTabsState  = {
    generalTabsList: { tabs: [] },
    generalTabItems: { items: [] }
};
const mutations = {
    SET_GENERAL_TABS_LIST: (state: GeneralTabsState, generalTabsList: GeneralTabsCollection) => {
        state.generalTabsList = generalTabsList;
    },
    SET_GENERAL_TABS_ITEMS: (state: GeneralTabsState, generalTabItems: TabItemsCollection) => {
        state.generalTabItems = generalTabItems;
    },
};
const actions = {
    getGeneralTabsList({ commit, dispatch }: any, params: any) {
        return service.getGeneralTabsList()
            .then(({ tabs }: any) => {
                commit('SET_GENERAL_TABS_LIST', tabs);
            });
    },
    showGeneralTabItems({ commit, dispatch }: any, params: any) {
        return service.showGeneralTabItems(params)
            .then(({ items }: any) => {
                commit('SET_GENERAL_TABS_ITEMS', items);
            });
    },
    updateGeneralTab({ commit, dispatch }: any, params: any) {
        return service.updateGeneralTab(params)
            .then(({ items }: any) => {
                commit('SET_GENERAL_TABS_ITEMS', items);
            });
    }
};
const getters = {
    generalTabsList: (state: GeneralTabsState) => state.generalTabsList,
    generalTabItems: (state: GeneralTabsState) => state.generalTabItems,
};
const generalTabs: Module<GeneralTabsState, any> = {
    state,
    getters,
    actions,
    mutations,
};
export default generalTabs;