import { Module } from 'vuex';
import service from '@/store/services/tabs-articles-service';
import { TabItemsCollection} from "@/types";

interface TabArticlesState {
    tabArticlesList: TabItemsCollection;
}

const state: TabArticlesState  = {
    tabArticlesList: { items: [] }
};
const mutations = {
    SET_TAB_ARTICLES_ITEMS: (state: TabArticlesState, tabArticlesList: TabItemsCollection) => {
        state.tabArticlesList = tabArticlesList;
    },
};
const actions = {
    getTabArticlesList({ commit, dispatch }: any, params: any) {
        return service.getTabArticles(params)
            .then(({ items }: any) => {
                commit('SET_TAB_ARTICLES_ITEMS', items);
            });
    },
    createTabArticle({ commit, dispatch }: any, params: any) {
        return service.createTabArticle(params)
            .then(({ items }: any) => {
                commit('SET_TAB_ARTICLES_ITEMS', items);
            });
    },
    updateTabArticle({ commit, dispatch }: any, params: any) {
        return service.updateTabArticle(params)
            .then(({ items }: any) => {
                commit('SET_TAB_ARTICLES_ITEMS', items);
            });
    },
    deleteTabArticle({ commit, dispatch }: any, params: any) {
        return service.deleteTabArticle(params)
            .then(({ items }: any) => {
                commit('SET_TAB_ARTICLES_ITEMS', items);
            });
    },
    searchTabArticle({ commit, dispatch }: any, params: any) {
        return service.searchTabArticles(params)
            .then(({ items }: any) => {
               commit('SET_TAB_ARTICLES_ITEMS', items);
            });
    }
};
const getters = {
    tabArticlesList: (state: TabArticlesState) => state.tabArticlesList,
};
const tabArticles: Module<TabArticlesState, any> = {
    state,
    getters,
    actions,
    mutations,
};
export default tabArticles;