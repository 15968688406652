import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Notifications from '@kyvg/vue3-notification'
import VCalendar from "v-calendar";
import 'v-calendar/style.css';
import '@/assets/css/app.scss'
import '@fortawesome/fontawesome-free/css/all.css';

createApp(App).use(store).use(router).use(VCalendar).use(Notifications).mount('#app')